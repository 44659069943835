import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RouterModule, Routes} from '@angular/router';
import {pristineSharedModule} from '../@pristine/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {pristineProgressBarModule, pristineSidebarModule, pristineThemeOptionsModule} from '../@pristine/components';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {pristineModule} from '../@pristine/pristine.module';
import {pristineConfig} from './pristine-config';
import {LayoutModule} from './layout/layout.module';
import {TicketService} from "./ticket.service";
import {AppComponent} from './app.component';
import {WebApiHttp} from "../@pristine/process/WebApiHttp.services";
import {MatsModule} from "./main/mats";
import {RepliesModule} from "./main/replies/replies.module";


const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'renewals',
    loadChildren: () => import('./main/renewals/renewals.module').then(m => m.RenewalsModule)
  },
  {
    path: 'e-invoice',
    loadChildren: () => import ('./main/e-invoice/e-invoice.module').then(m => m.EInvoiceModule)
  },
  {
    path: 'licenses',
    loadChildren: () => import ('./main/licenses/licenses.module').then(m => m.LicensesModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./main/tickets/tickets.module').then(m => m.TicketsModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./main/notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./main/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./main/support/support.module').then(m => m.SupportModule)
  },
  {
    path: '',
    redirectTo: '/dashboard/maindashboard',
    pathMatch: 'full',
  }
];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RepliesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,

    }), // ToastrModule added
    NgxSpinnerModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // pristine modules
    pristineModule.forRoot(pristineConfig),
    pristineProgressBarModule,
    pristineSharedModule,
    pristineSidebarModule,
    pristineThemeOptionsModule,


    // App modules
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatsModule
  ],
  providers: [TicketService, WebApiHttp],
  bootstrap: [AppComponent]
})
export class AppModule {
}
