import {Injectable, OnInit} from '@angular/core';
import {TicketModel} from "./modal/TicketModel";
import {WebApiHttp} from "../@pristine/process/WebApiHttp.services";
import {SessionManageMent} from "../@pristine/process/SessionManageMent";

@Injectable({
  providedIn: 'root',
}
)
export class TicketService implements OnInit{
  ticket: Array<TicketModel> = new Array<TicketModel>();

  constructor( private _webttpApi: WebApiHttp, private _session: SessionManageMent) {
    console.log("in Constructor");
  }

  ngOnInit() {
    console.log("in send onInit");

  }

 getTickets() {
   console.log("in get ticket");
    this._webttpApi.Get(this._webttpApi.ApiURLArray
      .get_Ticket + this._session.getName).then((res) => {
      console.log(res.length + 'res length');
        this.ticket = res;
        console.log(this.ticket.length + 'ticket length');
        this.sort(this.ticket);
    }).catch((err) =>{
      console.log("in send" + err);
    });
   console.log(this.ticket.length + "sending");
    // return this.ticket;//
  }

  sort(val): Array<TicketModel> {
    return val;
  }

}
