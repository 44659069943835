import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as data from '../../assets/static.json';

@Injectable({
  providedIn: 'root'
})

export class WebApiHttp {

    public globalurl: string = data.test_url;

  public ApiURLArray: any = {

    // User URL Start

    login: '/api/Login/Login',
    Logout: '/api/User/Logout',
    // dashboard
    DashboardData: '/api/Dashboard/get_DashBoard?Cust_ID=',
    dashboardTickets: '/api/Dashboard/get_last_five_ticket?Cust_ID=',
    dashboardNotification: '/api/Dashboard/get_notification_data?Cust_ID=',
    dashboardAlerts: '/api/Dashboard/get_alert_data?Cust_ID=',
    ticketGraph: '/api/Dashboard/get_ticket_month_wize_dashboard?Cust_ID=',
    year:'&year=',
    //Tickets
    get_Ticket_status:'/api/Ticket/get_Ticket?status=',
    customer_tickets_csv:'/api/Ticket/customer_tickets_csv',
    get_Ticket_status_custID: '&customer_id=',
    Insert_Ticket:'/api/Ticket/Insert_Ticket',
    update_Ticket: '/api/Ticket/Update_Ticket',
    get_Priorties : '/api/Ticket/priority',
    get_workType : '/api/Ticket/WorkTypeList',
    get_Project : '/api/Ticket/ProjectList?cust_id=',
    //Remarks,
    get_Remarks : '/api/Ticket/GetTicketRemarks',
    insert_Remark : '/api/Ticket/InsertTicketRemarks',

    //Ticket
    TicketMarkReopen:'/api/Ticket/TicketMarkReopen',


    //Invoices
    eInvoiceList : '/api/EInvoice/ListofE_invoice?Cust_ID=',
    eInvoiceInfo : '/api/EInvoice/E_invoiceFullDetails?e_invoice_id=',
    eInvoiceMonth : '/api/EInvoice/E_invoiceMonthInfo',
    //Licenses
    licenseList : '/api/Licenses/customer_licence_list?cust_id=',
    licenseDetail : '/api/Licenses/licence_and_renew_list?cust_id=' ,
    project:'&project_code=',
    //renewals
    renewalList : '/api/Licenses/customer_licence_renewal_list?cust_id=',
    //Subscription
    subscription:'/api/Subscription/customer_subscription_list?cust_id=',
    subDetails: '/api/Subscription/customer_subscription_details?cust_id=',
    //Suport
    support:'/api/SupportAggrement/customer_support_aggrement_list?cust_id=',

    //Forgot Password
    ResetPasswordOTPSend:'/api/Login/ResetPasswordOTPSend',
    ResetPasswordOTPVerify:'/api/Login/ResetPasswordOTPVerify',

};

  constructor(private httpClient: HttpClient
  ) {

  }

  get getHTTPHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getHTTPHeaderAuth(token: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  // post data to server
  async Post(path: string, jsondata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message});
      });
    }
  }

  // get data to the server
  async Get(path: string): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.get<any>(path, headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });
    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message});
      });
    }
  }

  // For formdata
  async PostFormData(path: string, formdata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var header = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, formdata).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'false',
            message: error.message
          })).catch(error => reject({
          condition: 'false',
          message: error.message
        }));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'false', message: e.message});
      });
    }
  }

  // post data to server and get two type of response
  Post_Data_GetFile(path: string, jsondata: any) {
    path = this.globalurl + path;
    const request = new HttpRequest('POST', path, jsondata, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile(path: string) {
    path = this.globalurl + path;
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  blobToString(b) {
    var urldata, x;
    urldata = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', urldata, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(urldata);
    return x.responseText;
  }
}
