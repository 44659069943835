import {EncriptDecript} from "./EncriptDecript";
import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionManageMent {

  static sessionLogout: boolean = false;
  currentUser$ = new Rx.ReplaySubject(2, 100);

  constructor(private _encriptDecript: EncriptDecript, private _router: Router, private activateroute: ActivatedRoute) {
    // subscriber 1
    this.currentUser$.subscribe((data) => {
      // console.log('Subscriber A:', data);
      if (data == '') {
        if (SessionManageMent.sessionLogout == false) {
          SessionManageMent.sessionLogout = true;
          if (!window.location.href.includes('/pages/auth/login-2'))
            this._router.navigateByUrl('/pages/auth/login-2');
        } else {
          setTimeout(() => {
              SessionManageMent.sessionLogout = false;
            },
            60000);
        }
      }
    });
// Behavior Subject
  }

  get getRoleId(): string {
    try {
      let roleid: string = this._encriptDecript.decrypt(localStorage.getItem('ZV_Role').toString());
      if (roleid != null && roleid != '' && roleid != undefined && roleid != '  ')
        return roleid;
      else
        return '';
    } catch (e) {
      return '';
    }
  }

  //  get session
  get getEmail(): string {
    try {
      let email_id: string = this._encriptDecript.decrypt(localStorage.getItem('ZV_SSID').toString());
      if (email_id != null && email_id != '' && email_id != undefined && email_id != '  ') {
        this.currentUser$.next(email_id);
        return email_id;
      } else {
        this.currentUser$.next('');
        return '';
      }
    } catch (e) {
      this.currentUser$.next('');
      return '';
    }
  }

  get getName(): string {
    try {
      let name: string = this._encriptDecript.decrypt(localStorage.getItem('ZV_SSNA').toString());
      if (name != null && name != '' && name != undefined && name != '  ')
        return name;
      else
        return '';
    } catch (e) {
      return '';
    }
  }

  get getCust(): string {
    try {
      let custID: string = this._encriptDecript.decrypt(localStorage.getItem('ZV_CUST').toString());
      if (custID != null && custID != '' && custID != undefined && custID != '  ')
        return custID;
      else
        return '';
    } catch (e) {
      return '';
    }
  }

  get getMenu(): any {
    try {
      return JSON.parse(this._encriptDecript.decrypt(localStorage.getItem('ZV_menu').toString()));
    } catch (e) {
      return undefined;
    }
  }


  // end set session

  setNameSession(name: string) {
    localStorage.setItem('ZV_SSNA', this._encriptDecript.encrypt(name));
  }

  setCustSession(custID: string) {
    localStorage.setItem('ZV_CUST', this._encriptDecript.encrypt(custID));
  }

  setMenuSession(menu: any) {
    localStorage.setItem('ZV_menu', this._encriptDecript.encrypt(JSON.stringify(menu).toString()));
  }

  //  set sessions
  setEmailSession(email_Id: string) {
    // this.setCookie('ZV_SSID', this._encriptDecript.encrypt(email_Id), 1);
    localStorage.setItem('ZV_SSID', this._encriptDecript.encrypt(email_Id));
  }

  setRoleSession(roleId: string) {
    localStorage.setItem('ZV_Role', this._encriptDecript.encrypt(roleId));
  }


  // end get

  // cookie management

  // private setCookie(name: string, value: string, expireDays: number, path: string = '') {
  //     let d: Date = new Date();
  //     d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //     let expires: string = `expires=${d.toUTCString()}`;
  //     let cpath: string = path ? `; path=${path}` : '';
  //     document.cookie = `${name}=${value}; ${expires}${cpath}`;
  // }
  //
  // private getCookie(name: string) {
  //     let ca: Array<string> = document.cookie.split(';');
  //     let caLen: number = ca.length;
  //     let cookieName = `${name}=`;
  //     let c: string;
  //
  //     for (let i: number = 0; i < caLen; i += 1) {
  //         c = ca[i].replace(/^\s+/g, '');
  //         if (c.indexOf(cookieName) == 0) {
  //             return c.substring(cookieName.length, c.length);
  //         }
  //     }
  //     return '';
  // }
  //
  // private deleteCookie(name) {
  //     this.setCookie(name, '', -1);
  // }

  // end cookie

}
