import {NgModule} from '@angular/core';

import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import { MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatTabsModule} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {pristineSharedModule} from "../../@pristine/shared.module";
import {MatListModule} from "@angular/material/list";

const mat =[
  MatButtonModule,
  MatExpansionModule,
  MatIconModule,
  MatDividerModule,
  MatCardModule,
  MatTabsModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  FlexLayoutModule,
  MatSelectModule,
  MatNativeDateModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSnackBarModule,
  MatSortModule,
  MatListModule,
  pristineSharedModule,

];
@NgModule({
   imports:[
     mat,
   ],
  exports: [mat],
})
export class MatsModule
{
}
