<div class="page-layout simple fullwidth inner-scroll" fxLayout="column" >
  <div class="p-16 fullheight" pristinePerfectScrollbar >
    <div class="mb-24  fullheight" fxLayout="column" fxLayoutAlign="start">
      <div class=" " fxFlex="1 0 auto" fxLayout="column"
           fxLayoutAlign="start">
        <div class="" fxFlex fxLayout="column" >
          <div class="m-4 p-4 " fxLayout="column" fxFlex fxLayoutAlign="start"  #scrollframe>
<!--            style=" overflow-y: auto;display: block;"-->
            <ng-container *ngIf="(empty) then cards else noCards" ></ng-container>
          </div>
          <div class="p-4" fxLayout="column" fxFlex="10" >
            <div fxLayout="row" fxLayoutGap="5px" fxFlex>
              <input autofocus class="message" matInput fxFlex autocomplete="off" [(ngModel)]='msg'>
              <button mat-button mat-raised-button color="accent" fxFlex="8" aria-label="Send" (click)="send()">
                <mat-icon>send</mat-icon>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #noCards>
  <div>
    <h1>NO REPLIES!!</h1>
  </div>
</ng-template>
<ng-template #cards>
    <div class="p-8 pb-0" *ngFor="let chat of GetChat" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="2px" [fxFlexAlign]='(chat.remarksby === name)?"end":"start"' #hell>
      <div class="p-16 pb-0" [class]='(chat.remarksby === name)?"msg_s":"msg_r"' fxFlex="90" fxLayout="column" >
      <!-- Remarks -->
      <div fxFlex style="word-break: break-all">{{chat.remarks}}</div>
      <div fxLayout="row" fxLayoutAlign = "start end" fxLayoutGap = 16px >
        <!-- Remarks By -->
        <div><h6>{{chat.reply_by}}</h6></div>
        <!-- Remarks On-->
        <div><h6>{{chat.remarkson | date :'dd/MM/YYYY'}}</h6></div>
      </div>
      </div>
    </div>

</ng-template>
