import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {WebApiHttp} from "../../../@pristine/process/WebApiHttp.services";
import {SessionManageMent} from "../../../@pristine/process/SessionManageMent";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-replies',
  templateUrl: './replies.component.html',
  styleUrls: ['./replies.component.scss']
})
export class RepliesComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChildren('hell') itemElements: QueryList<any>;
  private scrollContainer: any;
  GetChat: Array<Chat> = new Array<Chat>();
  count= [];
  name: string;
  msg='';
  ticket:string;
  subject:string;
  empty: boolean= false;
  temp_msg:any;
  temp:boolean=false;
  constructor(private router: Router,
              private _webHttpApi: WebApiHttp,
              private  _session: SessionManageMent,
              private route: ActivatedRoute,
              private spin: NgxSpinnerService) {
    this.ticket = this.route.snapshot.paramMap.get('state');
    //this.ticket=this.router.getCurrentNavigation().extras.state.ticket;
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe(_ => this.onItemElementsChanged());

  }

  private onItemElementsChanged(): void {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    this.name = this._session.getCust;
    var json = {
      "ticket_id": this.ticket,
    };
    this.spin.show();
    this._webHttpApi.Post(this._webHttpApi.ApiURLArray.get_Remarks, json).then(res => {
      this.GetChat = res;
      this.spin.hide();
      try {
        if (this.GetChat[0].condition === "True") {
          this.empty = true;

        } else {
          this.empty = false;
        }
      }catch (e){}

    });
  }
  send(){
    if(this.msg.trim().length > 0 ) {
      var json = {
        "ticket_id": this.ticket,
        "customer_id": this.name,
        "remarks": this.msg,
        "remarks_by": this.name,
      };
      this.temp_msg= json;
      this.temp = true
      this._webHttpApi.Post(this._webHttpApi.ApiURLArray.insert_Remark, json).then(res => {
        this.ngOnInit();
      }).catch(err =>{});
      this.msg='';
    }
  }

}


class Chat{
  condition: string;
  remarksby: string;
  remarks: string;
  remarkson: string;
  reply_by: string;
}

interface Message{
  "ticket_id": string,
  "customer_id": string,
  "remarks": string,
  "remarks_by": string,
  "subject": string,
}
