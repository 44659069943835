import {NgModule} from '@angular/core';

import {KeysPipe} from './keys.pipe';
import {GetByIdPipe} from './getById.pipe';
import {HtmlToPlaintextPipe} from './htmlToPlaintext.pipe';
import {FilterPipe} from './filter.pipe';
import {CamelCaseToDashPipe} from './camelCaseToDash.pipe';
import {FirstAnd_Split} from './FirstAnd_Split';
import {Time24to12Pipe} from './time24to12';
import {FilterByKey} from './data-filter.pipe';

@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    Time24to12Pipe,
    FilterByKey,
    FirstAnd_Split,
    CamelCaseToDashPipe,
  ],
  imports: [],
  exports: [

    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    Time24to12Pipe,
    FilterByKey,

    FirstAnd_Split,

    CamelCaseToDashPipe,

  ]
})
export class pristinePipesModule {
}
