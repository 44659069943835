import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {MatsModule} from "../mats";
import {RepliesComponent} from "./replies.component";
import {pristineSharedModule} from "../../../@pristine/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";

 const route : Routes = [
   {
     path : 'replies',
     component : RepliesComponent
   }
 ]

@NgModule({
    declarations: [RepliesComponent],
    exports: [
        RepliesComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(route),
        pristineSharedModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ]
})
export class RepliesModule { }
